import React from "react";
import { Link } from "react-router-dom";
import SubPageHead from "../../components/SubPageHead/SubPageHead";

export default function Rules() {
  return (
    <div>
      <SubPageHead title="Rules" />
      <br />



      <div className="wrap rules">
      <h1 style={{textDecoration: 'underline',color:'#F97E3F'}}>
          <strong>Note: Change in application format</strong>
        </h1>
<div>
<ol>
    <li>Now students can select multiple courses at a time by giving priority score.</li>
</ol>


<strong>
Example:
    </strong>
    <ul>
        <li>
        Priority 1 - Select “MBA” (if your first choice is MBA)
        </li>
        <li>
        Priority 2 - Select “MBA FC” (if your second choice is MBA FC)
        </li>
        <li>
        Priority 3 - Select “PGD-SBM” (if your third choice is PGD-SBM)
        </li>
        {/* <li>
        Priority 3 - Select “NA” or leave it empty (if you do not want to have a third choice)
        </li>
        <li>
        Priority 4 - same as above.
        </li> */}
    </ul>


<b style={{color:'#F97E3F'}}>Only priority 1 is compulsory, you can leave others empty or “NA” if you do not wish to apply to other streams.</b>

<br />
<br />

    <b style={{color:'#F97E3F'}}>The application fee is Nrs. 2,500 (regardless of number of choices you make in the priority)</b>

    <br />
    <br />

</div>
        <h2>
          <strong>A. Application Process Information</strong>
        </h2>
        <p>
          Applicants should provide all the necessary information. The fields
          that are marked with an asterisk (*) are compulsory.
        </p>
        <p>
          <strong>
            Must know about the application process
            <br />
          </strong>
          Your application will be handled in 3 stages viz.
        </p>
        <ul>
          <li>
            <strong> Process 1: Application Process</strong>
            <em>(Where you provide all your details)</em>
          </li>
        </ul>
        <p>
          - If your application from step 1a, is approved, you will receive an
          email link to upload your payment voucher.
        </p>
        <p>
          - If your application from step 1a, is rejected, you will receive an
          email regarding list of reasons for rejection.
        </p>
        <ul>
          <li>
            <strong> Process 2: Financial process </strong>
            <em>(Where you will have to proceed with payment)</em>
          </li>
        </ul>
        <p>
          - Once your application is approved, you will have to make a payment
          of NRs. 2500. <br />
          <strong>&nbsp;Bank Details:</strong>
        </p>
        <p>
          <strong>Account name:</strong> School of management TU
        </p>
        <p>
          <strong>Account number:</strong> 0501010000163
        </p>
        <p>
          <strong>Bank:</strong> Global IME Bank
        </p>
        <p>
          <strong>Branch:</strong> Kirtipur
          <br />- You will need to click a photo of the voucher and upload it in
          the link received and provide other details as required.
        </p>
        <ul>
          <li>
            <strong> Process 3: Final Process </strong>
            <em>(Where you will receive admit card)</em>
          </li>
        </ul>
        <p>
          - You will receive another email detailing if your financial payment
          was approved or rejected.
          <br />- If approved, you will receive your admit card in the form of
          pdf to be downloaded via email.
        </p>
        <p>
          - You will have to print the admit card in color and bring it along
          with you during your SOMAT exam.
          <strong>
            <br />
          </strong>
        </p>
        <br />
        <p>
          <strong>
            Note:
            <br />
          </strong>
          <em>
            Failing to provide accurate and/ or complete details will result in
            automatic cancellation of the application. SOMTU will not be
            responsible for any mistakes made by the applicants during their
            application process.
          </em>
          <strong>
            <br />
            <br />
          </strong>
        </p>
        <p>
          <strong>Steps to be followed</strong>
        </p>
        <h2>
          <strong>1. Link Visit</strong>
        </h2>
        <p>
          Please visit{" "}
          <a href="https://somatmba.com/">
            <em>https://somatmba.com/</em>
          </a>{" "}
          to proceed with your application.
        </p>
        <h3>1.1 To apply</h3>
        <p>
          Click on the <strong>&ldquo;Apply Now&rdquo;</strong> button present
          below the list of Open Applications.
        </p>
        <br />
        <h2>
          <strong>2. Process 1: Application Form</strong>
        </h2>
        <p>The application form is divided into six categories:</p>
        <ol>
          <li>General Details</li>
          <li>Academic Records</li>
          <li>Financial Support</li>
          <li>Referees</li>
          <li>Why SOMTU?</li>
          <li>Required Documents.</li>
        </ol>
        <h3>2.a General Details</h3>
        <ol>
          <li>
            <strong>
              {" "}
              Chose the stream you want to apply for from the drop down in first
              box
            </strong>{" "}
            (i.e. Applying For):
            <br />
            - Masters in Business Administration (MBA)
            <br />
            - MBA in Finance and Control (MBA FC)
            <br />
            {/* - Masters in Business Administration in Information Technology
            (MBA-IT) */}
            <br />
            {/* - Masters in Business Administration in Finance & Control
            (MBA-FC) */}
            <br />
            <br />

          </li>
          <li>
            <strong> Provide all valid information in the boxes.</strong>
            <br />
            - Chose category &ldquo;Regular&rdquo; if you are sponsoring your
            education by yourself else, chose &ldquo;Corporate Sponsor&rdquo; if
            any company or organization will be funding your fees.
            <br />
            - This section basically covers all your personal information.
            <br />
            <br />
            <strong>Note: </strong>
            <em>
              The email provided by applicant will be used as primary means of
              contact from where you will get information about your application
              progress and download admit card once everything is approved.
              SOMTU will not be held liable if you fail to provide information
              requested on time.
            </em>
          </li>
        </ol>
        <p>
          <img src="rules_files/image002.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 1.1: Sample General Details Page</em>
        </p>
        <h3>2.b Academic Records</h3>
        <ol>
          <li>Provide your Undergraduate/ Bachelor level of study info</li>
          <li>Provide your +2/ A-level or Equivalent study info</li>
        </ol>
        <p>iii. Provide your SLC/ SEE or Equivalent study info</p>
        <ol>
          <li>Provide your extra info (if you have taken any other)</li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <img src="rules_files/image004.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 1.2: Sample Academic Records Page</em>
        </p>
        <h3>2.c Financial Support</h3>
        <ol>
          <li>Describe in brief about how you plan to fund your studies?</li>
        </ol>
        <p>
          <img src="rules_files/image006.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 1.3: Sample Financial Support Page</em>
        </p>
        <h3>2.d Referees</h3>
        <ol>
          <li>
            Write down name &amp; contact info of two reputed person who would
            know you outside the family.
          </li>
        </ol>
        <p>
          <img src="rules_files/image008.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 1.4: Sample Referees Page</em>
        </p>
        <p>&nbsp;</p>
        <h3>2.e Why SOMTU?</h3>
        <ol>
          <li>
            Explain in details regarding your motivation to join SOMTU.{" "}
            <em>(This is your statement of purpose or SOP)</em>
          </li>
        </ol>
        <p>
          <strong>Tips:</strong>
        </p>
        <p>
          <em>
            Write down your SOP in a word document &amp; save it first. Review
            it, Proofread and then only copy it from your word file &amp; paste
            the contents in this box here. Your SOP must contain a minimum of
            800 words.
            <br />
            <br />
          </em>
        </p>
        <p>
          <img src="rules_files/image010.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 1.5: Sample Why SOMTU Page</em>
        </p>
        <h3>2.f Required Documents</h3>
        <ol>
          <li>
            <strong> Upload all the documents mentioned below:</strong>
            <br />
            <em>a. Passport sized photo</em>
          </li>
          <li>
            <em> Citizenship Front </em>
          </li>
          <li>
            <em> Citizenship Back</em>
          </li>
          <li>
            <em> SLC Mark Sheet</em>
          </li>
          <li>
            <em> SLC Character Certificate</em>
          </li>
          <li>
            <em> +2/A-level Mark Sheet</em>
          </li>
          <li>
            <em> +2/A-level Character Certificate</em>
          </li>
          <li>
            <em> Bachelor/ UNG Mark Sheet</em>
          </li>
          <li>
            <em> Bachelor/ UNG Character Certificate</em>
          </li>
          <li>
            <em> Bachelor/ UNG Migration Certificate</em>
          </li>
          <li>
            <em> Selfie with National Identity Card.</em>
          </li>
        </ol>
        <p>
          <strong>Tips: </strong>
        </p>
        <ol>
          <li>
            <em>
              {" "}
              Before proceeding with your application, kindly ensure that you
              have all your required files in one specific folder &amp; label
              them with your name followed by the document name.
              <br />
              <strong>Example:</strong> If your name is &ldquo;Hari Prasad
              Neupane&rdquo; and you want to upload the citizenship front image,
              then kindly, rename the file as
              &ldquo;Hari_Prasad_Neupane_Citizenship_Front&rdquo; and so on.
            </em>
          </li>
          <li>
            <em>
              <br />
              Scan all files, and ensure all of them are labelled correctly and
              then only proceed with the application.
            </em>
          </li>
        </ol>
        <p>
          <em>
            iii. Prepare a checklist of items, and then only proceed with your
            application.
          </em>
        </p>
        <p>
          <img src="rules_files/image012.png" alt="" />
        </p>
        <p>
          <em className="cap">
            Fig 1.6.1: Tips on preparing for documents upload
          </em>
        </p>
        <ol>
          <li>
            <strong> Accepted file types: </strong>PNG, JPG, JPEG, PDF
          </li>
        </ol>
        <p>
          <strong>iii. File upload limit:</strong> 1 MB
        </p>
        <p>
          <img src="rules_files/image014.png" alt="" />
        </p>
        <p>
          <em className="cap">
            Fig 1.6.2: Sample of required documents upload part - 1
          </em>
        </p>
        <p>
          <img src="rules_files/image016.png" alt="" />
        </p>
        <p>
          <em className="cap">
            Fig 1.6.3: Sample of required documents upload part - 2
          </em>
        </p>
        <p>
          <strong>Finally, </strong>
        </p>
        <p>
          <strong>After form submission</strong>, you will received an
          Identification Number (ID number) which can be used to track your form
          progress.
        </p>
        <p>
          <img src="rules_files/image017.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 1.7: Sample ID number for form upload</em>
        </p>
        <p>
          - You will receive an email informing that your form has been received
          and is under review.{" "}
          <em>(Look into sample email details below in step 5.a)</em>
        </p>
        <h2>
          <strong>3. Process 2: Financial Form</strong>
        </h2>
        <p>
          - Once your <strong>form is approved</strong> after Process 1, you
          will receive an email regarding payment details upload.{" "}
          <em>(Look into sample email details below in step 5.b.i)</em>
        </p>
        <p>
          - Only those applicants whose form has been approved will be able to
          proceed with payment details.
        </p>
        <p>
          <strong>Rules:</strong>
        </p>
        <ol>
          <li>
            Make sure <strong>payment</strong> is done under{" "}
            <strong>your name</strong>
            <strong>i.e. Applicant Name</strong> under <strong>Paid By </strong>
            section{" "}
            <em>
              (Vouchers with names other than that of applicants won&rsquo;t be
              accepted)
            </em>
          </li>
          <li>
            Make sure your <strong>Transaction ID</strong> is visible while you
            leave the bank counter{" "}
            <em>
              (If it is not clear, please confirm with bank before leaving)
            </em>
          </li>
          <li>
            Ensure that <strong>Date of Payment</strong> is written in{" "}
            <strong>BS</strong> only.
          </li>
        </ol>
        <p>
          <img src="rules_files/image018.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 2.1: Sample of Voucher upload form</em>
        </p>
        <h2>
          <strong>4. Process 3: Admit Card</strong>
        </h2>
        <p>
          - Finally after{" "}
          <strong>
            successful completion of Process - 1 &amp; Process &ndash; 2
          </strong>
          , you will receive a link to download your admit card in your email.
          <br />
          <br />
          <strong>Rules:</strong>
        </p>
        <ol>
          <li>
            Please download your admit card from the link sent in email and keep
            it safe with you.
            <br />
            2. Kindly ensure that your details matches with the details in the
            admit card. (If there is any issue, please contact support
            immediately).
          </li>
          <li>
            Print the admit card in color (Black &amp; White Print will not be
            accepted) and bring it along with you on your exam date.
          </li>
          <li>
            Also, bring your payment voucher along with you on your exam date.
          </li>
        </ol>
        <p>
          <img src="rules_files/image020.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 3.1: Sample Admit Card</em>
        </p>
        <h2>
          <strong>4. How To Check Form Status</strong>
        </h2>
        <p>
          <em>Visit</em>
          <a href="https://somatmba.com/form_status">
            <em>https://somatmba.com/form_status</em>
          </a>
          <em>
            {" "}
            or visit via the link sent in email
            <br />
            <br />
          </em>
          <strong>To check your form status, you will need:</strong>
        </p>
        <ol>
          <li>Identification Number (ID)</li>
          <li>Surname</li>
        </ol>
        <p>iii. Date of birth</p>
        <p>
          <img src="rules_files/image022.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 4.1: Check Form Status</em>
        </p>
        <h3>4.a Form Status Types:</h3>
        <p>Your form status is divided into 3 categories:</p>
        <ol>
          <li>Pending</li>
          <li>Approved</li>
        </ol>
        <p>iii. Declined</p>
        <h4>4.a.i Pending Status</h4>
        <p>- This means that your application is still under review.</p>
        <p>
          <img src="rules_files/image024.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 4.2: Form Status Pending</em>
        </p>
        <p>
          <strong>Note:</strong>
          <em>You will receive an email for the same as well.</em>
        </p>
        <h4>&nbsp;4.a.ii Approved Status</h4>
        <p>
          - This means that your application has been approved for further
          processing and you will receive a link in your email asking you to
          submit your bank voucher details.
        </p>
        <p>
          <img src="rules_files/image026.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 4.3: Form Status Approved</em>
        </p>
        <p>
          <strong>Note: </strong>
          <em>You will receive an email for the same.</em>
        </p>
        <h4>4.a.iii Rejected Status</h4>
        <p>
          - This means that your application has been rejected for further
          processing. You will receive a list of reasons declaring why your
          application was rejected.
          <br />- You can chose to re-apply by starting the entire process
          again. Please ensure that, you match the criteria and have fixed the
          rejection cases that came up earlier.
        </p>
        <p>
          <img src="rules_files/image028.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 4.4: Form Status Rejected</em>
        </p>
        <p>
          <strong>Note: </strong>
          <em>You will receive an email for the same.</em>
        </p>
        <h2>
          <strong>5. Types of Emails &amp; Information</strong>
        </h2>
        <p>
          You will receive emails during your application process in the
          following ways:
          <br />
          a. Application Received.
        </p>
        <ol>
          <li>Application Approved/ Rejected.</li>
          <li>Payment Voucher Received.</li>
          <li>Payment Voucher Approved/ Rejected.</li>
          <li>Admit card download.</li>
        </ol>
        <h3>5.a Application Received:</h3>
        <p>
          - You will receive an email from{" "}
          <a href="mailto:noreply@somatmba.com">noreply@somatmba.com</a> stating
          that your application has been received.
        </p>
        <p>
          - Please check the email in Promotions/ Updates etc. and also in Spam
          folders (if your form status is changed from pending to others while
          checking from step 4a)&nbsp;
        </p>
        <p>
          <img src="rules_files/image030.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.1: Application Received email </em>
        </p>
        <p>
          - The email will be titled{" "}
          <strong>&ldquo;Entrance for received&rdquo;</strong>
        </p>
        <p>
          <img src="rules_files/image032.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.2: Application Received info</em>
        </p>
        <h3>5.b Application Approved/ Rejected:</h3>
        <p>
          - You will receive an email stating your application has been accepted
          or rejected.
        </p>
        <h4>i. Accepted</h4>
        <p>
          - Once your application form is approved, you will receive an email
          stating your approval.
        </p>
        <p>
          <img src="rules_files/image034.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.3: Application Accepted info</em>
        </p>
        <p>
          - You can pay for your application fee, by visiting the bank and
          upload the receipt and payment details, by visiting the link sent you
          in email. (Please do not share confidential information with others)
        </p>
        <h4>ii. Rejected</h4>
        <p>
          - You will receive rejection reasons for your application as follows.
        </p>
        <p>
          <img src="rules_files/image036.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.4: Application Rejected info</em>
        </p>
        <p>
          - You can apply again, by starting the entire application process.
          Candidates are encouraged to fix the earlier reasons for rejection,
          while applying again. In case of any issues contact support.
        </p>
        <h3>5.c Payment Voucher Received:</h3>
        <p>
          - Once your application from step 5b is approved i.e. as in step
          5b.i., you will receive a link where you will have to submit the
          payment voucher for your application and provide payment details.
        </p>
        <p>
          - After uploading your payment voucher, you will receive an email
          stating &ldquo;Voucher Details Received&rdquo;
        </p>
        <p>
          <img src="rules_files/image038.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.5: Voucher Received info</em>
        </p>
        <h3>5.d Payment Voucher Accepted/ Rejected:</h3>
        <h4>i. Accepted</h4>
        <p>
          - Once your Voucher is approved, you will receive an email stating
          your approval.
        </p>
        <p>
          <img src="rules_files/image040.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.6: Voucher Accepted info</em>
        </p>
        <p>
          - Please be patient, while we prepare your admit card and send it to
          your shortly after this.
        </p>
        <h4>ii. Declined:</h4>
        <ul>
          <li>
            If your Payment Voucher has been declined, then please take a look
            at the reasons for rejection.
          </li>
          <li>
            Suggested: Review the reasons for rejection and ensure you upload a
            valid voucher with correct details.
          </li>
        </ul>
        <p>
          <img src="rules_files/image042.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.7: Voucher Rejected info</em>
        </p>
        <ul>
          <li>
            After resubmitting, please wait till your Payment Voucher goes from
            process 5.c again.
          </li>
          <li>
            Contact support if your payment has been declined multiple times.
          </li>
        </ul>
        <h3>5.d Download Admit Card:</h3>
        <p>
          - Candidates should download admit card from email and print it in
          color and bring it along during your exam time.
        </p>
        <p>
          - Candidates should also bring their payment slip/ voucher on their
          exam date.
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>B. FAQ</strong>
        </h2>
        <p>
          <strong>1. How do I reduce my file size?</strong>
        </p>
        <p>
          <strong>Ans:</strong> You can use different apps to reduce the file
          size or if you are using a PC, you can reduce file size using paint
          tool, visit
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.youtube.com/watch?v=hAMplAHLfSo"
          >
            here
          </a>{" "}
          for more details
        </p>
        <br />
        <p>
          <strong>2. What if I do not have all my documents with me?</strong>
        </p>
        <p>
          <strong>Ans:</strong> If in case you do not have one or more documents
          with you,
        </p>
        <p>
          a. You have to write a hand-written application mentioning why you do
          not have that specific document
          <br />
          b. Take a photo of your application and upload it in the specific
          section <br />
          <em>
            i.e. If you do not have your migration certificate, you write a
            hand-written application and sign it at the bottom. Then, you take a
            picture of the application and in the document upload section for
            migration certificate, you will upload that hand-written
            application.
            <br />
          </em>
          c. If you do not have more than one document, you have to repeat the
          process.
          <br />
          <em>
            i.e. Separate hand-written letters reasoning why you do not have an
            application and upload that in their specific document upload
            platform.
          </em>
        </p>
        <br />
        <p>
          <strong>3. Is all the document needed?</strong>
        </p>
        <p>
          <strong>Ans:</strong> Yes, all the documents are necessary. You can
          submit a handwritten application as mentioned in the step 6.B, but
          regardless of which, you will have to submit your original documents
          on your interview day{" "}
          <em>(i.e. If you are selected till interview process) </em>
        </p>
        <br />
        <p>
          <strong>
            4. What if my application was approved, but I couldn&rsquo;t proceed
            with payment before deadline?
          </strong>
        </p>
        <p>
          <strong>Ans:</strong> Candidates are suggested to complete their
          application 2-3 days before deadline. You will receive a link to
          upload your payment voucher only after the approval of your
          application which can take up to a maximum of 7 days to be approved.
          Thus, submit your applications early so that you can complete your
          entire application on time.
          <br />
          SOMTU will not be held responsible if a candidate fails to complete
          all the application process before time.&nbsp;
        </p>
        <br />
        <p>
          <strong>5. How do I contact support?</strong>
        </p>
        <p>
          <strong>Ans: </strong>In case of any issues with the application or
          for any queries, you can contact support at{" "}
          <a href="mailto:mgmt.somtu@gmail.com">mgmt.somtu@gmail.com</a> or at
          01- 4332719
          <br />
          <strong>Support hours:</strong> 11am - 3pm
        </p>
        <p>
          Average turnaround time for support execution is 48 hours. If you do
          not see the changes requested, please contact them again after 48
          hours.
        </p>
        <br />
        <p>
          <strong>6. Can my parents make payments on my behalf?</strong>
        </p>
        <p>
          Yes, your payment can be made by anyone, but we highly encourage
          applicants to make their own payments to ensure that there are no
          mistakes with the application process.
          <br />
          If someone else made payment on your behalf, please make sure that{" "}
          <strong>Your Name (Applicant Name) </strong>will appear in the{" "}
          <strong>Paid By</strong> section of the deposit voucher.
        </p>
        <p>
          Similarly, the <strong>Applicants Name</strong> has to be written in
          the <strong>Paid By</strong> section during the online voucher upload.
        </p>
        <p>
          <img src="rules_files/image044.png" alt="" />
        </p>
        <p>
          <em className="cap">Fig 5.8:Sample Voucher info</em>
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>C. Disclaimer:</strong>
        </h2>
        <ol>
          <li>
            Your forms will not be accepted after deadline day. SOMTU will not
            be held responsible for any errors or mistakes caused by the
            applicants during application. Candidates are requested to complete
            all the processes 2-3 days before deadline.
          </li>
          <li>
            Candidates should check emails on a timely basis and be updated with
            their application progress.
          </li>
          <li>
            This application is valid only for this term and won&rsquo;t be
            carried forward or reused.
          </li>
          <li>
            Your application for one stream cannot be transferred to other
            streams, you will have to apply again for the other stream.
          </li>
          <li>
            Automatic cancellation of the forms will be done, if candidate tries
            to repeat the application process for the same stream.
          </li>
          <li>
            Images to be uploaded should be clearly visible.
            <ul>
              <li>
                You can only upload Passport sized photographs in d your the
                specific section (any other entries besides it will be
                rejected.) For more info, visit{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://entrance.ioe.edu.np/Home/RegistrationImageInstructions"
                  target="_blank"
                >
                  here
                </a>
              </li>
              <li>
                The application will be rejected in case of any details is
                unclear in the uploaded document.
              </li>
            </ul>
          </li>

          <li>
            The final decision regarding acceptance/ rejection of an application
            will remain under the jurisdiction of SOMTU management.
          </li>
        </ol>
        <br />
        <br />
        <Link to="/entry_form">
          <button className="apply-now">Apply Now</button>
        </Link>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
